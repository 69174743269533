/* eslint-disable semi */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Collapse } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import classname from 'classnames';

//i18n
import { withTranslation } from 'react-i18next';
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json';
import { NAVBAR } from 'Apps';
// import { App_ID } from '../../'

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: '',
      arr_nav: [],
      navbar: {},
    };
  }

  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem('role'));
    if (obj != null) {
      this.setState({ role: obj.role });
    }
    const arr_nav = [];
    const navbar = NAVBAR;
    for (const i in navbar) {
      safeDeepSet(navbar, [i, 'name'], i);
      arr_nav.push(safeDeepGet(navbar, i, {}));
    }
    this.setState({ arr_nav, navbar });

    let matchingMenuItem = null;
    const ul = document.getElementById('navigation');
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = item => {
    item.classList.add('active');
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add('active'); // li
      const parent2 = parent.parentElement;
      parent2.classList.add('active'); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add('active'); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add('active'); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add('active'); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add('active'); // li
            }
          }
        }
      }
    }
    return false;
  };

  render() {
    const {  navbar } = this.state;
    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
              <Collapse
                isOpen={this.props.menuOpen}
                className="navbar-collapse"
                id="topnav-menu-content"
              >
                  <ul className="navbar-nav">
                    {/* Overview */}
                    {safeDeepGet(navbar, ['Overview', 'view'], 'off') ==
                    'on' ? (
                      <li className="nav-item dropdown">
                        <Link
                          to="/dashboard"
                          className="nav-link dropdown-toggle arrow-none"
                        >
                          {' '}
                          {this.props.t('Overview')}
                        </Link>
                      </li>
                    ) : null}

                    {/* business Point */}
                    {safeDeepGet(navbar, ['Business Point', 'view'], 'off') ==
                    'on' ? (
                      <li className="nav-item dropdown">
                        <Link
                          to="/#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({ bpState: !this.state.bpState });
                          }}
                          className="nav-link dropdown-toggle arrow-none"
                        >
                          <i className="bx bx-map me-2" />
                          {this.props.t('Business Point')}{' '}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname('dropdown-menu', {
                            show: this.state.bpState,
                          })}
                        >
                          <Row>
                            <Col lg={2}>
                              <div>
                                <Link
                                  to="/business-point/list"
                                  className="dropdown-item"
                                >
                                  {this.props.t('List Business Points')}
                                </Link>
                                {safeDeepGet(
                                  navbar,
                                  ['Business Point', 'edit'],
                                  'off'
                                ) == 'on' ? (
                                  <Link
                                    to="/business-point/create"
                                    className="dropdown-item"
                                  >
                                    {this.props.t('Add Business Point')}
                                  </Link>
                                ) : null}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </li>
                    ) : null}

                    {/* Product */}
                    {safeDeepGet(navbar, ['Product', 'view'], 'off') == 'on' ? (
                      <li className="nav-item dropdown">
                        <Link
                          to="/#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              productState: !this.state.productState,
                            });
                          }}
                          className="nav-link dropdown-toggle arrow-none"
                        >
                          <i className="bx bx-purchase-tag me-2" />
                          {this.props.t('Products & Stock')}{' '}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname('dropdown-menu', {
                            show: this.state.productState,
                          })}
                        >
                          <div className="dropdown">
                            <Link
                              to="/#"
                              className="dropdown-item dropdown-toggle arrow-none"
                              onClick={e => {
                                e.preventDefault();
                                this.setState({
                                  productState: !this.state.productState,
                                });
                              }}
                            >
                              {this.props.t('Product')}{' '}
                              <div className="arrow-down" />
                            </Link>
                            <div
                              className={classname('dropdown-menu', {
                                show: this.state.productState,
                              })}
                            >
                              <Link
                                to="/product/list"
                                className="dropdown-item"
                              >
                                {this.props.t('List Products')}
                              </Link>
                              {safeDeepGet(
                                navbar,
                                ['Product', 'edit'],
                                'off'
                              ) == 'on' ? (
                                <Link
                                  to="/product/create"
                                  className="dropdown-item"
                                >
                                  {this.props.t('Add Products')}
                                </Link>
                              ) : null}
                               <Link
                                to="/discount/list"
                                className="dropdown-item"
                              >
                                {this.props.t('Discount')}
                              </Link>
                            </div>
                          </div>

                          <div className="dropdown">
                            <Link
                              to="/#"
                              className="dropdown-item dropdown-toggle arrow-none"
                              onClick={e => {
                                e.preventDefault();
                                this.setState({
                                  productState: !this.state.productState,
                                });
                              }}
                            >
                              {this.props.t('Stock')}{' '}
                              <div className="arrow-down" />
                            </Link>
                            <div
                              className={classname('dropdown-menu', {
                                show: this.state.productState,
                              })}
                            >
                              {/* <Link to="/refill/list" className="dropdown-item">
                                {this.props.t('List Refiller')}
                              </Link> */}
                              <Link
                                to="/stock/business-point"
                                className="dropdown-item"
                              >
                                {this.props.t('Stock di Business Points')}
                              </Link>
                              <Link
                                to="/stock/warehouse"
                                className="dropdown-item"
                              >
                                {this.props.t('Stock di Warehouse')}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </li>
                    ) : null}

                    {/* Transactions */}
                    {safeDeepGet(navbar, ['Transaction', 'view'], 'off') ==
                    'on' ? (
                      <li className="nav-item dropdown">
                        <Link
                          to="/#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({ trxState: !this.state.trxState });
                          }}
                          className="nav-link dropdown-toggle arrow-none"
                        >
                          <i className="bx bx-file me-2" />
                          {this.props.t('Transaction')}{' '}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname('dropdown-menu', {
                            show: this.state.trxState,
                          })}
                        >
                          <div className="dropdown">
                            <Link
                              to="/#"
                              className="dropdown-item dropdown-toggle arrow-none"
                              onClick={e => {
                                e.preventDefault();
                                this.setState({
                                  transactionState:
                                    !this.state.transactionState,
                                });
                              }}
                            >
                              {this.props.t('Transaction')}{' '}
                              <div className="arrow-down" />
                            </Link>
                            <div
                              className={classname('dropdown-menu', {
                                show: this.state.transactionState,
                              })}
                            >
                              <Link
                                to="/transaction/list"
                                className="dropdown-item"
                              >
                                {this.props.t('List')}
                              </Link>
                              <Link
                                to="/transaction/report"
                                className="dropdown-item"
                              >
                                {this.props.t('Reports')}
                              </Link>
                              <Link
                                to="/transaction/snapshot"
                                className="dropdown-item"
                              >
                                {this.props.t('Snapshot')}
                              </Link>
                              <Link
                                to="/invoice/list"
                                className="dropdown-item"
                              >
                                {this.props.t('Invoice')}
                              </Link>
                            </div>
                          </div>
                          <div className="dropdown">
                            <Link
                              to="/#"
                              className="dropdown-item dropdown-toggle arrow-none"
                              onClick={e => {
                                e.preventDefault();
                                this.setState({
                                  refundState: !this.state.refundState,
                                });
                              }}
                            >
                              {this.props.t('Refund')}{' '}
                              <div className="arrow-down" />
                            </Link>
                            <div
                              className={classname('dropdown-menu', {
                                show: this.state.refundState,
                              })}
                            >
                              <Link
                                to="/transaction/chat"
                                className="dropdown-item"
                              >
                                {this.props.t('Refund Request')}
                              </Link>
                              {/* <Link to="/coupon/list" className="dropdown-item">
                                {this.props.t('Coupon')}
                              </Link> */}
                            </div>
                          </div>
                          {safeDeepGet(navbar, ['Payout', 'view'], 'off') ==
                          'off' ?(
                          <></>
                        ) : (
                          <>
                          <div className="dropdown">
                            <Link
                              to="/#"
                              className="dropdown-item dropdown-toggle arrow-none"
                              onClick={e => {
                                e.preventDefault();
                                this.setState({
                                  payoutState: !this.state.payoutState,
                                });
                              }}
                            >
                              {this.props.t('Payout')}{' '}
                              <div className="arrow-down" />
                            </Link>
                            <div
                              className={classname('dropdown-menu', {
                                show: this.state.payoutState,
                              })}
                            >
                              <Link
                                to="/payout/bankaccount"
                                className="dropdown-item"
                              >
                                {this.props.t('Bank Account')}
                              </Link>
                              <Link
                                to="/payout/list"
                                className="dropdown-item"
                              >
                                {this.props.t('Payout')}
                              </Link>
                            </div>
                          </div>
                        </>
                        )}
                          <Row>
                            <Col lg={2}>
                              <div></div>
                            </Col>
                          </Row>
                        </div>
                      </li>
                    ) : null}

                    {/* Account */}
                    {safeDeepGet(navbar, ['Management', 'view'], safeDeepGet(navbar, ['Account', 'view'], 'off')) == 'on' ? (
                      <li className="nav-item dropdown">
                        <Link
                          to="/#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              accountState: !this.state.accountState,
                            });
                          }}
                          className="nav-link dropdown-toggle arrow-none"
                        >
                          <i className="bx bx-user-circle me-2" />
                          {this.props.t('Management')}{' '}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname('dropdown-menu', {
                            show: this.state.accountState,
                          })}
                        >
                          <Row>
                            <Col lg={2}>
                              <div>
                                <Link to="/role" className="dropdown-item">
                                  {this.props.t('Role')}
                                </Link>
                                <Link
                                  to="/account/list"
                                  className="dropdown-item"
                                >
                                  {this.props.t('Account')}
                                </Link>
                                <Link
                                to="/error/code"
                                className="dropdown-item"
                              >
                                {this.props.t('Error')}
                              </Link>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </li>
                    ) : null}
                   

                    {/* Activity */}
                    {safeDeepGet(navbar, ['Activity', 'view'], 'off') ==
                    'on' ? (
                      <li className="nav-item dropdown">
                        <Link
                          to="/#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              activityState: !this.state.activityState,
                            });
                          }}
                          className="nav-link dropdown-toggle arrow-none"
                        >
                          <i className="bx bx-file me-2" />
                          {this.props.t('Activity')}{' '}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname('dropdown-menu', {
                            show: this.state.activityState,
                          })}
                        >
                          <Row>
                            <Col lg={2}>
                              <div>
                                <Link
                                  to="/refund/list"
                                  className="dropdown-item"
                                >
                                  {this.props.t('Refund History')}
                                </Link>
                                <Link
                                  to="/business-point/history/planogram"
                                  className="dropdown-item"
                                >
                                  {this.props.t('Planogram History')}
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </li>
                    ) : null}
                    {/* {safeDeepGet(navbar, ['Subscription', 'view'], 'off') == 'on' ? (
                      <li className="nav-item dropdown">
                        <Link
                          to="/#"
                          onClick={e => {
                            e.preventDefault();
                            this.setState({
                              subsState: !this.state.subsState,
                            });
                          }}
                          className="nav-link dropdown-toggle arrow-none"
                        >
                          <i className="bx bx-mail-send me-2" />
                          {this.props.t('Subscription')}{' '}
                          <div className="arrow-down" />
                        </Link>
                        <div
                          className={classname('dropdown-menu', {
                            show: this.state.subsState,
                          })}
                        >
                          <Row>
                            <Col lg={2}>
                              <div>
                                <Link
                                  to="/subs/list"
                                  className="dropdown-item"
                                >
                                  {this.props.t('List')}
                                </Link>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </li>
                    ) : null} */}
                  </ul>
              </Collapse>
            </nav>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(Navbar));
