/* eslint-disable no-unused-vars */
import { call, put, takeEvery } from 'redux-saga/effects'

import {
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_VM,
// eslint-disable-next-line semi
} from './actionTypes';

import { loading, result } from './actions'
import { getInstance } from '../../firebase/helper'
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json'
import Result from 'iotera-base/result'
import { device_map as deviceMap, getUser, setUser } from 'Apps/device'

function* list(action) {
  yield put(loading(LIST))
  const deviceId = safeDeepGet(action, ['payload'])
  const wsResult = yield call(getUser, deviceId)
  if (safeDeepGet(wsResult, 'code') == Result.HTTP.OK) {
    const tableBody = safeDeepGet(wsResult, ['body', 'table_data'])
    const device_id = safeDeepGet(wsResult, ['body', 'device_id'])
    const tableMaps = []
    for (const id in tableBody) {
      const rfid = safeDeepGet(tableBody[id], ['RFID', 'value'])
      const saldo = Number(safeDeepGet(tableBody[id], ['SALDO', 'value']))
      const state = Number(safeDeepGet(tableBody[id], ['STATE', 'value']))
      const data = { rfid: rfid, saldo: saldo, state: state }
      // tableMaps[id] = data;
      tableMaps.push(data)
    }
    yield put(
      result(LIST, {
        code: Result.SUCCESS,
        body: { user: tableMaps, name: device_id },
      })
    )
  } else {
    yield put(result(LIST, { code: Result.FAILED }))
    return;
  }
}

function* initCreate() {
  yield put(result(INIT_CREATE, { code: Result.SUCCESS, body: {} }))
}

function* create(action) {
  yield put(loading(CREATE))
  const payload = safeDeepGet(action, ['payload', 'user'])
  const deviceId = safeDeepGet(action, ['payload', 'deviceId'])
  const config = []
  const selection = Object.keys(payload[0])
  config.push(selection)
  for (const i in payload) {
    const arrData = []
    const rfid = payload[i].rfid
    arrData.push(rfid)
    const saldo = Number(payload[i].saldo)
    arrData.push(saldo)
    // const state = Number(payload[i].state);
    // arrData.push(state);
    config.push(arrData)
  }
  // console.log(config)
  const wsResult = yield call(setUser, deviceId, config)
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const code = safeDeepGet(wsResult,'code')
    yield put(result(CREATE, { code }))
  } else if (safeDeepGet(wsResult, 'code') == 200) {
    const code = safeDeepGet(wsResult,'code')
    yield put(result(CREATE, { code }))
  } else  {
    yield put(result(CREATE, { code: Result.FAILED}))
  }
}
function* list_vm() {
  yield put(loading(LIST_VM))
  const firebaseInstance = getInstance()
  let wsResult = yield call(firebaseInstance.refiller.list)
  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const listMap = wsResult.body
    const refillerList = []
    for (const id in listMap) {
      let refillerName = safeDeepGet(listMap, [id, 'name'])
      let refillerId = safeDeepGet(listMap, [id, 'code'])
      refillerList.push({ label: refillerName, value: refillerId })
    }
    yield put(result(LIST_VM, { code: Result.SUCCESS, body: refillerList }))
    return;
  }
  yield put(result(LIST_VM, { result: { code: Result.FAILED } }))
}
function* read(action) {
  yield put(loading(READ))
  const sku = safeDeepGet(action, ['payload', 'sku'])
  const firebaseInstance = getInstance()
  const wsResult = yield call(firebaseInstance.refiller.read, sku)
  wsResult.body.id = sku
  if (safeDeepGet(wsResult, 'code') !== Result.SUCCESS) {
    yield put(result(READ, { code: Result.FAILED }))
    return;
  }

  yield put(
    result(READ, {
      code: 0,
      body: wsResult.body,
    })
  )
}

function* update(action) {
  yield put(loading(UPDATE))
  const sku = safeDeepGet(action, ['payload', 'id'])
  const payload = safeDeepGet(action, 'payload')
  const firebaseInstance = getInstance()
  const wsResult = yield call(firebaseInstance.refiller.update, sku, payload)
  const code = safeDeepGet(wsResult, 'code')

  yield put(result(UPDATE, { code }))
}

function* stockUpdate(action) {
  yield put(loading(STOCK_UPDATE))
  const sku = safeDeepGet(action, ['payload', 'sku'])
  const payload = safeDeepGet(action, 'payload')
  const firebaseInstance = getInstance()

  // Upload image to firebase storage
  const imageFile = safeDeepGet(action, ['payload', 'imageFile'])
  let imageUrl = null
  if (imageFile != null) {
    const imageExt = safeDeepGet(action, ['payload', 'imageExt'])
    const wsResult = yield call(
      firebaseInstance.productStorage.replace,
      imageFile,
      sku,
      imageExt
    )
    if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
      imageUrl = safeDeepGet(wsResult, ['body', 'url'])
    }
  }
  if (imageUrl != null) {
    payload['img_url'] = imageUrl
  }

  const wsResult = yield call(
    firebaseInstance.product.updateStock,
    sku,
    payload
  )
  const code = safeDeepGet(wsResult, 'code')

  yield put(result(STOCK_UPDATE, { code }))
}

function* delete_(payload) {
  yield put(loading(DELETE))
  const firebaseInstance = getInstance()
  const deviceId = safeDeepGet(payload, ['payload', 'sku'], '')
  const wsResult = yield call(firebaseInstance.refiller.delete_, deviceId)
  const code = safeDeepGet(wsResult, 'code')
  yield put(result(DELETE, { code }))
}

function* productSaga() {
  yield takeEvery(LIST, list)
  yield takeEvery(INIT_CREATE, initCreate)
  yield takeEvery(CREATE, create)
  yield takeEvery(READ, read)
  yield takeEvery(UPDATE, update)
  yield takeEvery(STOCK_UPDATE, stockUpdate)
  yield takeEvery(DELETE, delete_)
  yield takeEvery(LIST_VM, list_vm)
}

export default productSaga
