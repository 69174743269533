import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"

import dashboardSaga from "./dashboard/saga"

import businessPointSaga from './business-point/saga'

import userSaga from './User/saga'
import projectSaga from './Project/saga'
import mappingSaga from './Mapping/saga'
import bpfeeSaga from './business-point-fee/saga'
import transactionAdminSaga from './transaction-admin/saga'
import payoutAdminSaga from './Payout/saga'
import dasboardAdminSaga from './dashboard/saga'
import tagsSaga from './tags/saga'
export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(dashboardSaga),
    fork(businessPointSaga),
    fork(userSaga),
    fork(projectSaga),
    fork(bpfeeSaga),
    fork(mappingSaga),
    fork(transactionAdminSaga),
    fork(payoutAdminSaga),
    fork(dasboardAdminSaga),
    fork(tagsSaga),
  ])
}
