/* eslint-disable semi */
export const LOADING = "LOADING";
export const RESULT = "RESULT";

export const LIST = "BUSINESS_POINT_LIST";
export const HISTORY = "BUSINESS_POINT_HISTORY";
export const STOCK_BUSINESS_POINT = "STOCK_BUSINESS_POINT";
export const INIT_CREATE = "BUSINESS_POINT_INIT_CREATE";
export const CREATE = "BUSINESS_POINT_CREATE";
export const READ_INFO = "BUSINESS_POINT_READ_INFO";
export const UPDATE_INFO = "BUSINESS_POINT_UPDATE_INFO";
export const UPDATE_SHARE_REVENUE = "BUSINESS_POINT_UPDATE_SHARE_REVENUE";
export const SET_PLANOGRAM_CSV = "BUSINESS_POINT_SET_PLANOGRAM_CSV";
export const DELETE = "BUSINESS_POINT_DELETE";
export const INPUT_CSV = "BUSINESS_POINT_INPUT_CSV";
export const CONFIG_PLANOGRAM = "BUSINESS_POINT_CONFIG_PLANOGRAM";
export const NOTIFICATIONS = "BUSINESS_POINT_NOTIFICATIONS";
export const CLOSE_NOTIFICATIONS = "BUSINESS_POINT_CLOSE_NOTIFICATIONS";
export const CLEAR_CASH = "BUSINESS_POINT_CLEAR_CASH";
export const LIST_VM = "BUSINESS_POINT_LIST_VM";
export const SERI_VM = "BUSINESS_POINT_SERI_VM";
export const LIST_PRODUCT = "BUSINESS_POINT_LIST_PRODUCT";
export const UPDATE_CALIBRATION = "BUSINESS_POINT_UPDATE_CALIBRATION";
export const READ_PRODUCT = "BUSINESS_POINT_READ_PRODUCT";
export const GET_SHARE_REVENUE = "BUSINESS_POINT_GET_SHARE_REVENUE";

export const UPDATE_PLANOGRAM_RETAIL_OLD = "BUSINESS_POINT_UPDATE_PLANOGRAM_RETAIL_OLD";
export const UPDATE_PLANOGRAM_MCPRO = "BUSINESS_POINT_UPDATE_PLANOGRAM_MCPRO";
export const UPDATE_PLANOGRAM_MILANO = "BUSINESS_POINT_UPDATE_PLANOGRAM_MILANO";
export const UPDATE_PLANOGRAM_RETAIL = "BUSINESS_POINT_UPDATE_PLANOGRAM_RETAIL";
export const UPDATE_PLANOGRAM_LEVENDING = "BUSINESS_POINT_UPDATE_PLANOGRAM_LEVENDING";
export const UPDATE_PLANOGRAM = "BUSINESS_POINT_UPDATE_PLANOGRAM";
export const UPDATE_PLANOGRAM_FRANKE = "BUSINESS_POINT_UPDATE_PLANOGRAM_FRANKE";

export const READ_PLANOGRAM_RETAIL_OLD = "BUSINESS_POINT_READ_PLANOGRAM_RETAIL_OLD";
export const READ_PLANOGRAM_MCPRO = "BUSINESS_POINT_READ_PLANOGRAM_MCPRO";
export const READ_PLANOGRAM_MILANO = "BUSINESS_POINT_READ_PLANOGRAM_MILANO";
export const READ_PLANOGRAM_RETAIL = "BUSINESS_POINT_READ_PLANOGRAM_RETAIL";
export const READ_PLANOGRAM_LEVENDING = "BUSINESS_POINT_READ_PLANOGRAM_LEVENDING";
export const READ_PLANOGRAM = "BUSINESS_POINT_READ_PLANOGRAM";
export const READ_PLANOGRAM_FRANKE = "BUSINESS_POINT_READ_PLANOGRAM_FRANKE";

export const READ_PLANOGRAM_WATER_DISPENSER = "BUSINESS_POINT_READ_PLANOGRAM_WATER_DISPENSER";
export const UPDATE_PLANOGRAM_WATER_DISPENSER = "BUSINESS_POINT_UPDATE_PLANOGRAM_WATER_DISPENSER";
export const RESET_FLOWMETER = "BUSINESS_POINT_RESET_FLOWMETER";