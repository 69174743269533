/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Pages403 from '../pages-403';
const navbar = NAVBAR;
const permision = PERMISION;
// Strap
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
} from 'reactstrap';

// Availity
import { AvField, AvForm } from 'availity-reactstrap-validation';

// Select
import Select from 'react-select';

// Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

// Iotera
import Result from 'iotera-base/result';
import { safeDeepGet, isEmpty } from 'iotera-base/utility/json';
// Toast
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// Redux
import { connect } from 'react-redux';
import {
  getShareRevenue,
  initCreate,
  readInfo,
  updateShareRevenue,
} from '../../store/business-point/actions';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import { list_vm } from '../../store/landlord/actions';
import { list_distributor } from '../../store/distributor/actions';
import {
  APPLICATION_ID,
  NAME_PROJECT,
  NAVBAR,
  PERMISION,
  buildTitle,
} from 'Apps';
let typeOptions = [
  { label: 'Fixed', value: 'fixed' },
  { label: 'Share Revenue', value: 'share_revenue' },
];
class BusinessPointShareRevenue extends Component {
  constructor(props) {
    super(props);

    let isEdit = false;
    let deviceId = null;

    const path = this.props.match.path;
    if (path.includes('/options/')) {
      deviceId = safeDeepGet(this.props.match.params, 'id');
    }

    this.state = {
      selectedType:  { label: 'Fixed', value: 'fixed' },
      deviceId,
      loading: false,
      serialNumber: '0000',
    };

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleCancleClick = this.handleCancleClick.bind(this);
    this.showToast = this.showToast.bind(this);
    this.toggleLoader = this.toggleLoader.bind(this);
    this.handelChangelandlord = this.handelChangelandlord.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
  }

  componentDidMount() {
    this.props.ReadBusinessPointInfo(this.state.deviceId);
  }
  toggleLoader = () => {
    if (!this.state.loading) {
      this.setState({ loading: true });
      setTimeout(() => this.setState({ loading: false }), 3000);
    } else {
      this.setState({ loading: false });
    }
  };
  handelChangelandlord(value) {
    this.setState({ landlord: value.value });
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.readInfoResult !== prevProps.readInfoResult) {
      // Insert value of VM select
      const code = safeDeepGet(
        this.props,
        ['readInfoResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code == Result.SUCCESS) {
        const newState = {};
        
        const share_amount = safeDeepGet(this.props, [
          'readInfoResult',
          'body',
          'fee',
          'share_amount',
        ], 0);
        const share_revenue =Number(((1 -safeDeepGet(this.props, [
          'readInfoResult',
          'body',
          'fee',
          'share_amount',
        ],
          0
        ) )* 100).toFixed(2));
        const type_share = safeDeepGet(this.props, [
          'readInfoResult',
          'body',
          'fee',
          'type',
        ],  safeDeepGet(this.props, [
          'readInfoResult',
          'body',
          'type',
        ], 'fixed'));
        newState['selectedType'] = {value: type_share};

        newState['share_amount'] = type_share == "fixed"? share_amount : share_revenue ;
      
        if (!isEmpty(newState)) {
          this.setState(newState);
        }
      }
    }
    if (this.props.updateInfoResult !== prevProps.updateInfoResult) {
      // Success update info
      const code = safeDeepGet(
        this.props,
        ['updateInfoResult', 'code'],
        Result.UNKNOWN_ERROR
      );

      if (code === Result.SUCCESS) {
        this.showToast();
        setTimeout(() => {
          this.props.history.replace('/business-point/list');
        }, 5000);
      } else {
        if (code != Result.UNKNOWN_ERROR) {
          this.showToastFailed();
        }
      }
    }
  }
  handleCancleClick() {
    this.props.history.push(`/business-point/list/`);
  }

  showToastFailed() {
    const { isEdit } = this.state;
    var toastType = 'error';
    var message = 'Gagal Tambah Business Point';
    if (isEdit) {
      toastType = 'error';
      message = 'Gagal Update Business Point';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  showToast() {
    const { isEdit } = this.state;
    var toastType = 'success';
    var message = 'Berhasil Tambah Business Point';
    if (isEdit) {
      toastType = 'success';
      message = 'Berhasil Update Business Point';
    }

    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }
  handleValidSubmit(event, values) {
    const new_value = {
      ...values,
      type: this.state.selectedType.value,
      id: this.state.deviceId
    };
    this.props.UpdateBusinessPointInfo(new_value);

  }

  handleChange(event) {
    this.setState({ sn: event.target.value });
  }
  handleTypeChange(value) {
    this.setState({
      selectedType: value,
    });
  }

  handleDate = date => {
    this.setState({ date });
  };
  render() {
    const loading = safeDeepGet(this.props, 'loading', true);
    const {
      isEdit,
      selectedType,
      share_amount,
    
    } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{buildTitle('Business Point')}</title>
          </MetaTags>
          {!permision ||
          safeDeepGet(navbar, ['Business Point', 'edit'], 'off') == 'off' ? (
            <Pages403 />
          ) : (
            <Container fluid={true}>
              <Breadcrumbs
                title="Business Point"
                breadcrumbItem={isEdit ? 'Edit' : 'Add'}
              />
              <AvForm onValidSubmit={this.handleValidSubmit}>
                <Row>
                  <Col lg="12">
                    <Card>
                      <CardBody>
                        <h4 className="card-title">VM Fee Information</h4>
                        <p className="card-title-desc">
                          Pilih Tipe Pembayaran Sewa Lokasi.
                        </p>
                        <Row>
                          <Col md={6}>
                            <FormGroup className="mb-3 select2-container">
                              <Label>Pilih Tipe</Label>
                              <Select
                                value={typeOptions.filter(function (option) {
                                  return option.value === selectedType.value;
                                })}
                                onChange={this.handleTypeChange}
                                options={typeOptions}
                                classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <AvField
                                value={share_amount}
                                name="share_amount"
                                label={
                                  selectedType.value == 'fixed'
                                    ? 'Sewa Lokasi per Bulan'
                                    : 'Share Revenue Lokasi (%)'
                                }
                                placeholder={
                                  selectedType.value == 'fixed'
                                    ? 'Biaya Sewa Lokasi'
                                    : 'Share Revenue Lokasi (%)'
                                }
                                type="number"
                                errorMessage="Angka dengan Benar"
                                className="form-control"
                                onChange={this.handleChange}
                                validate={{
                                  required: { value: true },
                                  pattern: { value: "^[0-9]+(.[0-9]+)?$" }
                               }}
                              
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>

                    <div className="d-flex flex-wrap gap-2 float-end mb-4">
                      <Button
                        onClick={this.handleCancleClick}
                        color="secondary"
                      >
                        Cancel
                      </Button>{' '}
                      <Button
                        type="submit"
                        color="primary"
                        // disabled={loading }
                      >
                        {/* {this.state.loading ? (
                          <Spinner color="light" size="sm"></Spinner>
                        ) : "Simpan"} */}
                        Simpan
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </Container>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  initCreateResult: safeDeepGet(state, ['businessPoint', 'initCreate'], {}),
  readInfoResult: safeDeepGet(state, ['businessPoint', 'getShareRevenue'], {}),
  updateInfoResult: safeDeepGet(state, ['businessPoint', 'updateShareRevenue'], {}),
  loading: safeDeepGet(state, ['businessPoint', 'loading'], true),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  InitBusinessPointInfoCreate: () => dispatch(initCreate()),
  ReadBusinessPointInfo: id => dispatch(getShareRevenue(id)),
  UpdateBusinessPointInfo: payload => dispatch(updateShareRevenue(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessPointShareRevenue);
