import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Collapse } from 'reactstrap'
import { Link, withRouter } from 'react-router-dom'
import classname from 'classnames'

//i18n
import { withTranslation } from 'react-i18next'

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      role: '',
    }
  }

  componentDidMount() {
    const obj = JSON.parse(localStorage.getItem('role'))
    if (obj != null) {
      this.setState({ role: obj.role })
    }

    let matchingMenuItem = null
    const ul = document.getElementById('navigation')
    const items = ul.getElementsByTagName('a')
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = item => {
    item.classList.add('active')
    const parent = item.parentElement
    if (parent) {
      parent.classList.add('active') // li
      const parent2 = parent.parentElement
      parent2.classList.add('active') // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add('active') // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add('active') // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add('active') // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add('active') // li
            }
          }
        }
      }
    }
    return false
  };

  render() {
    const { role } = this.state

    return (
      <React.Fragment>
        <div className="topnav">
          <div className="container-fluid">
            <nav
              className="navbar navbar-light navbar-expand-lg topnav-menu"
              id="navigation"
            >
                            <Collapse
                  isOpen={this.props.menuOpen}
                  className="navbar-collapse"
                  id="topnav-menu-content"
                >
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <Link
                        to="/admin/dashboard"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        {' '}
                        {this.props.t('Overview')}
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        to="/admin/project"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        {' '}
                        {this.props.t('Project')}
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <Link
                        to="/admin/transaction"
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        {' '}
                        {this.props.t('Transaction')}
                      </Link>
                    </li>

                    <li className="nav-item dropdown">
                      <Link
                        to="/#"
                        onClick={e => {
                          e.preventDefault()
                          this.setState({
                            payOutState: !this.state.payOutState,
                          })
                        }}
                        className="nav-link dropdown-toggle arrow-none"
                      >
                        {/* <i className="bx bx-money me-2" /> */}
                        {this.props.t('Payout')} <div className="arrow-down" />
                      </Link>
                      <div
                        className={classname('dropdown-menu', {
                          show: this.state.payOutState,
                        })}
                      >
                        <Row>
                          <Col lg={2}>
                            <div>
                              <Link
                                to="/admin/payout/bankaccount"
                                className="dropdown-item"
                              >
                                {this.props.t('Bank Account')}
                              </Link>
                              <Link
                                to="/admin/payout/list"
                                className="dropdown-item"
                              >
                                {this.props.t('Payout')}
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </li>
                  </ul>
                </Collapse>
             
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '10vw',  
                }}
                className="text-white"
              >
              </div>
            </nav>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

Navbar.propTypes = {
  location: PropTypes.object,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(Navbar))
