/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */
import { call, put, takeEvery } from 'redux-saga/effects'

import {
  LIST,
  INIT_CREATE,
  CREATE,
  READ,
  UPDATE,
  DELETE,
  STOCK_UPDATE,
  LIST_VM,
  SUMMARY
} from './actionTypes'

import { loading, result } from './actions'
import { getInstance } from '../../firebase/helper'
import { safeDeepGet, safeDeepSet } from 'iotera-base/utility/json'
import Result from 'iotera-base/result'
import { adminListUser, deletedUser,get,getSummary } from '../../webservice/device'

function* list() {
  yield put(loading(LIST))
  const wsResult = yield call(adminListUser)
  const code = safeDeepGet(wsResult, 'code', Result.FAILED)
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const userBody = safeDeepGet(wsResult, ['body', 'users'], [])
    yield put(result(LIST, { code: Result.SUCCESS, body: userBody }))
    return;
  }
  yield put(result(LIST, { result: { code: Result.FAILED } }))
}

function* delete_(payload) {
  yield put(loading(DELETE))
  const userUid = safeDeepGet(payload, ['payload', 'uid'], '')
  let wsResult = yield call(deletedUser, userUid)
  const code = safeDeepGet(wsResult, 'code')
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    yield put(result(DELETE, { code: code }))
  } else {
    yield put(result(DELETE, { code: Result.FAILED }))
  }
}

function* create(action) {
  yield put(loading(CREATE))
  const payload = safeDeepGet(action, ['payload', 'data'], [])
  let data = {}
  for (const i in payload) {
    const key = safeDeepGet(payload[i], 'keys')
    const url = safeDeepGet(payload[i], 'url')
    if (url != null) {
      if(key == 'multitenancy' || key == 'autoRefund'){
        if(url == 'TRUE' || url == 'true'){
          data[key] = true
        } else {
          data[key] = false

        }
      } else {
        data[key] = url
      }
    }
  }
  const firebaseInstance = getInstance()
  const wsResult = yield call(firebaseInstance.mapping.create, data)

  if (safeDeepGet(wsResult, 'code') == Result.SUCCESS) {
    const code = safeDeepGet(wsResult, 'code')
    yield put(result(CREATE, { code }))
  } else if (safeDeepGet(wsResult, 'code') == 200) {
    const code = safeDeepGet(wsResult, 'code')
    yield put(result(CREATE, { code }))
  } else {
    yield put(result(CREATE, { code: Result.FAILED }))
  }
}

function* read(payload) {
  yield put(loading(READ))
  const firebaseInstance = getInstance()
  const wsResult = yield call(firebaseInstance.trxAdmin.list_graph, payload)
  const code = safeDeepGet(wsResult, 'code', Result.FAILED)
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const trxBody = safeDeepGet(wsResult, 'body', {})
    for (const id in trxBody) {
      const device = safeDeepGet(trxBody, [id, 'product', 'detail'], {})
      let i = 0
      for (const device_id in device) {
        if (i == 0) {
          safeDeepSet(trxBody, [id, 'device_id'], device_id)
        } else if (i == 1) {
          safeDeepSet(trxBody, [id, 'device_id1'], device_id)
        }
      }
    }
    let sum_per_current = sum_per_date(trxBody)
    let threshold = []
    let min_threshold = []
    for (const j in sum_per_current) {
      const amount = safeDeepGet(sum_per_current[j], 'y', 0)
      threshold.push(amount)
    }
    const chart_data = [
      {
        name: 'Total',
        data: threshold,
      },
    ]
    yield put(result(READ, { code: Result.SUCCESS, body: chart_data }))
    // eslint-disable-next-line semi
    return;
  }
  yield put(result(READ, { result: { code: Result.FAILED } }))
}
function sum_per_date(nsMap) {
  let now = new Date()
  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns])
    {
      nsMap ? (now = new Date(val.time.timestamp)) : (now = new Date())
    }
  }
  var resultArr = []
  var dateArr = [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ]
  // const month = now.getMonth();
  // const year = now.getFullYear();
  // const date = new Date(year, month, 1);
  // const dates = [];
  let obj = {}
  // while (date.getMonth() === month) {
  //   const day = new Date(date);
  //   dates.push(('0' + day.getDate()).slice(-2));
  //   date.setDate(date.getDate() + 1);
  // }
  for (const i in dateArr) {
    obj = { x: dateArr[i], y: 0 }
    resultArr.push(obj)
  }

  for (const ns in nsMap) {
    var val = safeDeepGet(nsMap, [ns])
    // const date = new Date(val.time.timestamp)
    // // date.setMonth(date.getMonth()-1)
    // let val_date = date.toLocaleString(undefined, {
    //   month: '2-digit',
    // })
    const reference = safeDeepGet(val, ['detail', 'reference_no'], '')
    const split = reference.split('/')
    const tanggal = split[1]
    const splitdate = tanggal.split('-')
    const date = new Date(splitdate[1] + '-' + splitdate[0])
    // date.setMonth(date.getMonth()-1)
    let val_date = date.toLocaleString(undefined, {
      month: '2-digit',
    })
    var index = dateArr.indexOf(val_date)
    if (index == -1) {
      dateArr.push(val_date)
        // resultArr[Number(val_date) - 1].y += val.payment.amount;
        resultArr[Number(val_date) - 1].y += (safeDeepGet(
          val,
          ['payment', 'amount'],
          0
        )).toFixed(2)
    } else {
        // resultArr[Number(val_date) - 1].y += val.payment.amount;
        resultArr[Number(val_date) - 1].y += (safeDeepGet(
          val,
          ['payment', 'amount'],
          0
        )).toFixed(2)
    }
  }
  return resultArr
}
function* summary(payload){
  yield put(loading(SUMMARY))
  const firebaseInstance = getInstance()
  let wsResult = yield call(firebaseInstance.mapping.list)
  const code = safeDeepGet(wsResult, 'code', Result.FAILED)
  if (code == Result.SUCCESS || code == Result.HTTP.OK) {
    const mapBody = safeDeepGet(wsResult, 'body')
    const app_id = safeDeepGet(payload, ['payload','appId'])
    const multitenancy = safeDeepGet(payload, ['payload','multitenancy'], false)
    const url = safeDeepGet(mapBody, [app_id, 'url_summary'], '')
    wsResult = yield call(get, url , app_id, multitenancy)
    const code =safeDeepGet(wsResult, 'code', Result.FAILED)
    if(code == Result.SUCCESS || code == Result.HTTP.OK){
      const sumBody = safeDeepGet(wsResult, 'body', {})
      yield put(result(SUMMARY, {result: { code: Result.SUCCESS, body: sumBody}}))
      return;
    }
    yield put(result(SUMMARY, { result: { code: Result.FAILED } }))
  }
  yield put(result(SUMMARY, { result: { code: Result.FAILED } }))
}
function* projectsSaga() {
  yield takeEvery(LIST, list)
  yield takeEvery(DELETE, delete_)
  yield takeEvery(CREATE, create)
  yield takeEvery(READ, read)
  yield takeEvery(SUMMARY, summary)
}

export default projectsSaga
