/* eslint-disable semi */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { APPLICATION_ID } from 'Apps';
import React from 'react';
import {
    Label,
    Container,
    Row,
    Col,
    FormGroup,
    InputGroup,
    Button,
    Card,
    CardTitle,
    CardBody,
  } from 'reactstrap';
  import MetaTags from 'react-meta-tags';
  import Select from 'react-select'

const VMComponent = ({isEdit, vmOptions, selectedVM,handleSelectVM,typeOptions, selectedType, handleSelectType}) => {
  if (APPLICATION_ID == '1000000159' || APPLICATION_ID == '1000000211') {
    return (
      <Row>
        <Col md={12}>
          <FormGroup className="mb-3 select2-container">
            <Label>Pilih VM</Label>
            <Select
              value={
                isEdit
                  ? vmOptions.filter(function (option) {
                      return option.value === selectedVM;
                    })
                  : selectedVM
              }
              onChange={handleSelectVM}
              options={vmOptions}
              classNamePrefix="select2-selection"
              isDisabled={isEdit}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col md={6}>
          <FormGroup className="mb-3">
            <Label>Tipe VM</Label>
            <Select
              value={
                isEdit
                  ? typeOptions.filter(function (option) {
                      return option.value === selectedType;
                    })
                  : selectedType
              }
              onChange={handleSelectType}
              options={typeOptions}
              classNamePrefix="select2-selection"
              // isDisabled={isEdit}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup className="mb-3 select2-container">
            <Label>Pilih VM</Label>
            <Select
              value={
                isEdit
                  ? vmOptions.filter(function (option) {
                      return option.value === selectedVM;
                    })
                  : selectedVM
              }
              onChange={handleSelectVM}
              options={vmOptions}
              classNamePrefix="select2-selection"
              isDisabled={isEdit}
            />
          </FormGroup>
        </Col>
      </Row>
    );
  }
};

export default VMComponent;
