/* Import */
/* eslint-disable no-undef */
/* eslint-disable no-redeclare */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import firebase from 'firebase/compat/app';
import {
  collection,
  query,
  where,
  orderBy,
  startAfter,
  limit,
  getDocs,
} from 'firebase/firestore';

// Add the Firebase products that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import { safeDeepGet, safeGet } from 'iotera-base/utility/json';
// time
import Base from './base';
import { last } from 'lodash';
import { PAGE_SIZE } from 'Apps';
const momenttz = require('moment-timezone');
const moment = require('moment');
const pageSize = PAGE_SIZE;

export default class Transaction extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'transaction');
  }
  next_list_pagination = async payload => {
    return new Promise((resolve, reject) => {
      var filter_bp = safeDeepGet(payload, 'businessPoints');
      var filter_ts = safeDeepGet(payload, 'transactionStatus');
      var filter_pm = safeDeepGet(payload, 'bank');
      var filter_ds = safeDeepGet(payload, 'dispenseStatus');
      var [filter_sd, filter_ed] = safeDeepGet(payload, 'dateRange');
      var lastDocs = safeDeepGet(payload, 'lastDocs', null);
      var lastDocsPrev = safeDeepGet(payload, 'lastDocsPrev', null);
      var fistDocs = safeDeepGet(payload, 'fistDocs', null);
      var arr_timeStamp = safeDeepGet(payload, 'arr_timeStamp', []);
      var arr_bp = safeDeepGet(payload, 'arr_bp', []);
      var allBp = safeDeepGet(payload, 'allBp', 'true');
      var page = safeDeepGet(payload, 'page', 0);
      if (filter_bp == 'all' || filter_bp == null) {
        filter_bp = null;
      }
      if (filter_pm == 'all' || filter_pm == null) {
        filter_pm = null;
      }
      if (filter_ts == 'all' || filter_ts == null) {
        filter_ts = null;
      }
      if (filter_ds == 'all' || filter_ds == null) {
        filter_ds = null;
      }
      if (filter_sd == 'all' || filter_sd == null) {
        filter_sd = null;
      }
      if (filter_ed == 'all' || filter_ed == null) {
        filter_ed = null;
      }

      let query = this.collection;

      if (filter_bp) {
        query = query.where('product.device_id', '==', filter_bp);
      } else {
       if(allBp == 'false'){
         query = query.where('product.device_id', 'in', arr_bp);
       } 
      }
      if (filter_pm) {
        query = query.where('payment.method', '==', filter_pm);
      }

      if (filter_ts) {
        query = query.where('detail.transaction_status', '==', filter_ts);
      }

      if (filter_ds) {
        if (filter_ds != '-') {
          query = query.where('detail.dispense_status', '==', filter_ds);
        }
      }
      if (filter_sd) {
        const timezone = 'Asia/Jakarta';
        const start = momenttz.tz(filter_sd, timezone).startOf('day');
        const startTime = firebase.firestore.Timestamp.fromMillis(
          start.valueOf()
        );
        query = query.where('time.firestore_timestamp', '>=', startTime);
      }

      if (filter_ed) {
        const timezone = 'Asia/Jakarta';
        const end = momenttz.tz(filter_ed, timezone).endOf('day');
        const endTime = firebase.firestore.Timestamp.fromMillis(end.valueOf());
        query = query.where('time.firestore_timestamp', '<=', endTime);
      }
      const list = {};
      if (lastDocs || lastDocs != undefined) {
        const endTime = firebase.firestore.Timestamp.fromMillis(lastDocs);
        query = query
          .orderBy('time.firestore_timestamp', 'desc')
          .startAt(endTime);
      }
      var prvData = arr_timeStamp[page - 1];
      if (fistDocs || fistDocs !== undefined) {
        const startTime = firebase.firestore.Timestamp.fromMillis(
          arr_timeStamp[page - 1] + 1000
        );
        const endTime = firebase.firestore.Timestamp.fromMillis(fistDocs);
        query = query
          .orderBy('time.firestore_timestamp', 'desc')
          .startAt(startTime)
          .endBefore(endTime);
        // .limitToLast(5)
      }
      query = query.limit(pageSize);
      const documentSnapshots = getDocs(query);
      documentSnapshots.then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            if (filter_ds == '-') {
              const data = doc.data();
              const ds = safeDeepGet(data, ['detail', 'dispense_status'], '-');
              if (ds == '-') {
                list[doc.id] = doc.data();
              }
            } else {
              list[doc.id] = doc.data();
            }
          });
          let fistDocs = null;
          let lastDocs = null;
          if (querySnapshot.docs[0] != undefined) {
            const firstData = querySnapshot.docs[0].data() ?? null;
            fistDocs = firstData ? firstData.time.timestamp : null;
            const lastData =
              querySnapshot.docs[querySnapshot.docs.length - 1].data();
            lastDocs = lastData ? lastData.time.timestamp : null;
          }
          resolve({ code: 0, body: list, fistDocs, lastDocs, lastDocsPrev });
        },
        error => {
          console.log(error);
          resolve({ code: -1, body: list });
        }
      );
    });
  };
  list_pagination = async payload => {
    return new Promise((resolve, reject) => {
      var filter_bp = safeDeepGet(payload, 'businessPoints');
      var filter_ts = safeDeepGet(payload, 'transactionStatus');
      var filter_pm = safeDeepGet(payload, 'bank');
      var filter_ds = safeDeepGet(payload, 'dispenseStatus');
      var [filter_sd, filter_ed] = safeDeepGet(payload, 'dateRange');
      var arr_bp = safeDeepGet(payload, 'arr_bp', []);
      var allBp = safeDeepGet(payload, 'allBp', 'true');
      if (filter_bp == 'all' || filter_bp == null) {
        filter_bp = null;
      }
      if (filter_pm == 'all' || filter_pm == null) {
        filter_pm = null;
      }
      if (filter_ts == 'all' || filter_ts == null) {
        filter_ts = null;
      }
      if (filter_ds == 'all' || filter_ds == null) {
        filter_ds = null;
      }
      if (filter_sd == 'all' || filter_sd == null) {
        filter_sd = null;
      }
      if (filter_ed == 'all' || filter_ed == null) {
        filter_ed = null;
      }

      let query = this.collection;
      if (filter_bp) {
        query = query.where('product.device_id', '==', filter_bp);
      } else {
       if(allBp == 'false'){
         query = query.where('product.device_id', 'in', arr_bp);
       } 
      }
      if (filter_pm) {
        query = query.where('payment.method', '==', filter_pm);
      }

      if (filter_ts) {
        query = query.where('detail.transaction_status', '==', filter_ts);
      }

      if (filter_ds) {
        if (filter_ds != '-') {
          query = query.where('detail.dispense_status', '==', filter_ds);
        }
      }
      if (filter_sd) {
        const timezone = 'Asia/Jakarta';
        const start = momenttz.tz(filter_sd, timezone).startOf('day');
        const startTime = firebase.firestore.Timestamp.fromMillis(
          start.valueOf()
        );
        query = query.where('time.firestore_timestamp', '>=', startTime);
      }

      if (filter_ed) {
        const timezone = 'Asia/Jakarta';
        const end = momenttz.tz(filter_ed, timezone).endOf('day');
        const endTime = firebase.firestore.Timestamp.fromMillis(end.valueOf());
        query = query.where('time.firestore_timestamp', '<=', endTime);
      }
      const list = {};
      const firstQuery = query
        .orderBy('time.firestore_timestamp', 'desc')
        .limit(pageSize);
      const documentSnapshots = getDocs(firstQuery);

      documentSnapshots.then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            if (filter_ds == '-') {
              const data = doc.data();
              const ds = safeDeepGet(data, ['detail', 'dispense_status'], '-');
              if (ds == '-') {
                list[doc.id] = doc.data();
              }
            } else {
              list[doc.id] = doc.data();
            }
          });
          let fistDocs = null;
          let lastDocs = null;
          if (querySnapshot.docs[0] != undefined) {
            const firstData = querySnapshot.docs[0].data() ?? null;
            fistDocs = firstData ? firstData.time.timestamp : null;
            const lastData =
              querySnapshot.docs[querySnapshot.docs.length - 1].data();
            lastDocs = lastData ? lastData.time.timestamp : null;
          }
          resolve({ code: 0, body: list, fistDocs, lastDocs });
        },
        error => {
          console.log(error);
          resolve({ code: -1, body: list });
        }
      );
    });
  };
  search = orderID => {
    return new Promise((resolve, reject) => {
      // var orderId = safeDeepGet(orderID, 'orderId');
      const list = {};
      this.collection
      .where('detail.order_id', '>=', orderID).where('detail.order_id', '<=', orderID + '~').limit(pageSize)
        .get().then(
        snapshot => {
          snapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          console.log(error);
          resolve({ code: -1, body: list });
        }
      );
    });
  };
  list = payload => {
    return new Promise((resolve, reject) => {
      var filter_bp = safeGet(payload, 'businessPoints');
      var filter_ts = safeGet(payload, 'transactionStatus');
      var filter_pm = safeGet(payload, 'bank');
      var filter_ds = safeGet(payload, 'dispenseStatus');
      var filter_sd = safeGet(payload, 'dateRange')[0];
      var filter_ed = safeGet(payload, 'dateRange')[1];

      if (filter_bp == 'all' || filter_bp == null) {
        filter_bp = null;
      }
      if (filter_pm == 'all' || filter_pm == null) {
        filter_pm = null;
      }
      if (filter_ts == 'all' || filter_ts == null) {
        filter_ts = null;
      }
      if (filter_ds == 'all' || filter_ds == null) {
        filter_ds = null;
      }
      if (filter_sd == 'all' || filter_sd == null) {
        filter_sd = null;
      }
      if (filter_ed == 'all' || filter_ed == null) {
        filter_ed = null;
      }

      let query = this.collection;

      if (filter_bp) {
        query = query.where('product.device_id', '==', filter_bp);
      }
      if (filter_pm) {
        query = query.where('payment.method', '==', filter_pm);
      }

      if (filter_ts) {
        query = query.where('detail.transaction_status', '==', filter_ts);
      }

      if (filter_ds) {
        if(filter_ds != '-'){
          query = query.where('detail.dispense_status', '==', filter_ds);
        }
      }
      if (filter_sd) {
        const timezone = 'Asia/Jakarta';
        moment.tz.setDefault(timezone);
        const now = momenttz.tz(filter_sd, timezone);
        const Date_1 = now.clone().subtract(0, 'days').startOf('days');
        const startTime = Date_1.valueOf();
        var startfulldate = firebase.firestore.Timestamp.fromMillis(startTime);
        query = query.where(
          "time.firestore_timestamp",
          ">=",
          startfulldate
        );
      }

      if (filter_ed) {
        const timezone = 'Asia/Jakarta';
        moment.tz.setDefault(timezone);
        const end = momenttz.tz(filter_ed, timezone);
        const Date_2 = end.clone().subtract(0, 'days').endOf('days');
        const endTime = Date_2.valueOf();
        var endfulldate = firebase.firestore.Timestamp.fromMillis(endTime);
        
        query = query.where(
          "time.firestore_timestamp",
          "<=",
          endfulldate
        );
      }

      const list = {};
      query.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            if(filter_ds == '-'){
              const data = doc.data();
              const ds = safeGet(data, ['detail', 'dispense_status'], '-')
              if(ds == '-'){
                list[doc.id] = doc.data();
              }
            } else{
              list[doc.id] = doc.data();
            }
          });
          resolve({ code: 0, body: list });
        },
        error => {
          console.log(error);
          resolve({ code: -1, body: list });
        }
      );
    });
  };

  nett_settlement = payload => {
    return new Promise((resolve, reject) => {
      var filter_month = safeGet(payload, 'month');
      var filter_year = safeGet(payload, 'year');
      var filter_bp = safeGet(payload, 'businessPoints');
      var filter_sd = safeGet(payload, 'date')[0];
      var filter_ed = safeGet(payload, 'date')[1];
      if (filter_bp == 'all' || filter_bp == null) {
        filter_bp = null;
      }
      if (filter_sd == 'all' || filter_sd == null) {
        filter_sd = null;
      }
      if (filter_ed == 'all' || filter_ed == null) {
        filter_ed = null;
      }
      var startDate = new Date(filter_year, filter_month, 1);
      var endDate = new Date(filter_year, filter_month + 1, 0);
      let query = this.collection;
      if (filter_bp) {
        query = query.where('product.device_id', '==', filter_bp);
      }

      if (filter_sd) {
        const timezone = 'Asia/Jakarta';
        moment.tz.setDefault(timezone);
        const now = momenttz.tz(filter_sd, timezone);
        const Date_1 = now.clone().subtract(0, 'days').startOf('days');
        const startTime = Date_1.valueOf();
        var startfulldate = firebase.firestore.Timestamp.fromMillis(startTime);
        query = query.where(
          "time.firestore_timestamp",
          ">=",
          startfulldate
        );
      } else {
        if (startDate) {
          const timezone = 'Asia/Jakarta';
        moment.tz.setDefault(timezone);
        const now = momenttz.tz(filter_sd, timezone);
        const Date_1 = now.clone().subtract(0, 'days').startOf('days');
        const startTime = Date_1.valueOf();
        var startfulldate = firebase.firestore.Timestamp.fromMillis(startTime);
        query = query.where(
          "time.firestore_timestamp",
          ">=",
          startfulldate
        );
        }
        if (endDate) {
            const timezone = 'Asia/Jakarta';
        moment.tz.setDefault(timezone);
        const end = momenttz.tz(filter_ed, timezone);
        const Date_2 = end.clone().subtract(0, 'days').endOf('days');
        const endTime = Date_2.valueOf();
        var endfulldate = firebase.firestore.Timestamp.fromMillis(endTime);
        
        query = query.where(
          "time.firestore_timestamp",
          "<=",
          endfulldate
        );
        }
      }

      if (filter_ed) {
        const d = filter_ed;
        d.setDate(d.getDate() + 1);
        var endfulldate = firebase.firestore.Timestamp.fromDate(d);
        query = query.where(
          'time.firestore_timestamp',
          '<=',
          endfulldate
        )
      } else {
        if (startDate) {
          const timezone = 'Asia/Jakarta';
        moment.tz.setDefault(timezone);
        const now = momenttz.tz(filter_sd, timezone);
        const Date_1 = now.clone().subtract(0, 'days').startOf('days');
        const startTime = Date_1.valueOf();
        var startfulldate = firebase.firestore.Timestamp.fromMillis(startTime);
        query = query.where(
          "time.firestore_timestamp",
          ">=",
          startfulldate
        );
        }
        if (endDate) {
            const timezone = 'Asia/Jakarta';
        moment.tz.setDefault(timezone);
        const end = momenttz.tz(filter_ed, timezone);
        const Date_2 = end.clone().subtract(0, 'days').endOf('days');
        const endTime = Date_2.valueOf();
        var endfulldate = firebase.firestore.Timestamp.fromMillis(endTime);
        
        query = query.where(
          "time.firestore_timestamp",
          "<=",
          endfulldate
        );
        }
      }

      const list = {};
      query.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          console.log(error);
          resolve({ code: -1, body: list });
        }
      );
    });
  };
  detail = orderID => {
    return new Promise((resolve, reject) => {
      // var orderId = safeGet(orderID, 'orderId');
      const list = {};
      this.collection
        .where('__name__', '==', orderID)
        .get()
        .then(
          snapshot => {
            snapshot.docs.map(doc => {
              list[doc.id] = doc.data();
            });
            resolve({ code: 0, body: list });
          },
          error => {
            console.log(error);
            resolve({ code: -1, body: list });
          }
        );
    });
  };

  read = deviceId => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .where('product.device_id', '==', deviceId)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };

  randomDate = (start, end, startHour, endHour) => {
    var date = new Date(+start + Math.random() * (end - start));
    var hour = (startHour + Math.random() * (endHour - startHour)) | 0;
    date.setHours(hour);
    return date;
  };

  random = () => {
    return new Promise((resolve, reject) => {
      // for (let step = 0; step < 25; step++) {
      //   var rd = this.randomDate(new Date(2021, 9, 1), new Date(2021, 9, 31), 0, 24) ;
      //   var frd = firebase.firestore.Timestamp.fromDate(rd);
      //   const amount = Math.floor(Math.random() * 11) * 100;
      //   const deviceId =  Math.floor(Math.random() * 3) == 0 ? "d2aa1803-133c-4244-bc52-40475bae2eac" : "8ec5ac6d-7934-49be-8911-15b443bd551e";
      //   const sku =  Math.floor(Math.random() * 3) == 0 ? "SOSRO-TB-ORI-200" : "NESTLE-BB-ORI-189";
      //   const isDispense =  Math.floor(Math.random() * 3) == 0 ? true : false;
      //   const data = {
      //       device_id: deviceId,
      //       paymentDetails: {
      //         paymentAmount: amount,
      //         paymentMethod: "QRIS-ESPAY",
      //       },
      //       sku: sku,
      //       time.firestore_timestamp  firestore_timestamp: frd,
      //         microsecondsSinceEpoch: rd.getTime() * 1000,
      //       },
      //       transactionDetails: {
      //         dispenseTime: rd.getTime() * 1000,
      //         isDispense: isDispense,
      //         orderId: "" + rd.getTime() * 1000,
      //         transactionStatus: "settlement",
      //       },
      //     }
      //     this.collection.doc("" + rd.getTime() * 1000).set(data);
      // }
    });
  };

  updateRefund = (id, payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (
          key === 'order_id' ||
          key === 'transaction_status' ||
          key === 'refund_time'
        ) {
          updatedPayload[key] = payload[key];
        }
      }

      this.collection
        .doc(id)
        .set(
          {
            detail: updatedPayload,
          },
          { merge: true }
        )
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  checkPayment = (id) =>{ 
    console.log(id)
    return new Promise((resolve, reject) => {
      const list = [];
      this.collection
      .where('detail.order_id', '==', id)
      .get()
      .then(
        snapshot => {
          snapshot.docs.map(doc => {
            list.push(doc.data())
          });
          if(list.length < 1 ){
            resolve({ code: -1 });
          } else {
            resolve({ code: 0, body: list });
          }
        },
        error => {
          console.log(error);
          resolve({ code: -1, body: list });
        }
      );
    });
  }
}
