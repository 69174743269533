/* eslint-disable react/prop-types */
/* eslint-disable semi */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-drawer/lib/react-drawer.css';
import { safeDeepGet } from 'iotera-base/utility/json';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

// Import menuDropdown
import ProfileMenu from '../../Base/components/CommonForBoth/TopbarDropdown/ProfileMenu';
import AlertDevice from '../../Base/components/CommonForBoth/TopbarDropdown/AlertDevice';
import Notification from '../../Base/components/CommonForBoth/TopbarDropdown/Notification';

import logoSv from '../../Base/assets/images/smartvending/horizontalColour.png';
// Redux Store
import { notification } from '../store/business-point/actions';
import { list_alert_current } from '../store/alert/actions';
import { APPLICATION_ID } from 'Apps';
//i18n

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      open: false,
      position: 'right',
      role: '',
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  /**
   * Toggle sidebar
   */
  componentDidMount() {
    // Load business point
    this.props.GetNotifications();
    this.props.LoadCurrentList();
    const obj = JSON.parse(localStorage.getItem('role'));
    if (obj != null) {
      this.setState({ role: obj.role });
    }
  }
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }
  render() {
    const list = safeDeepGet(this.props, ['result', 'length'], []);
    const listCurrent = safeDeepGet(
      this.props,
      ['resultCurrent', 'length'],
      []
    );
    const windowDimension = window.innerWidth;
    const isMobile = windowDimension <= 640;
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              {isMobile ? (
                <div></div>
              ) : (
                <div className="navbar-brand-box">
                  <Link to="/" className="logo logo-dark">
                    <span className="logo-sm">
                      <img src={logoSv} alt="" height="30" width="100" />
                    </span>
                    <span className="logo-lg">
                      <img src={logoSv} alt="" height="30" width="100" />
                    </span>
                  </Link>

                  <Link to="/" className="logo logo-light">
                    <span className="logo-sm">
                      <img src={logoSv} alt="" height="30" width="100" />
                    </span>
                    <span className="logo-lg">
                      <img src={logoSv} alt="" height="30" width="100" />
                    </span>
                  </Link>
                </div>
              )}

              <button
                type="button"
                className="btn btn-sm px-3 font-size-16 d-lg-none header-item"
                data-toggle="collapse"
                onClick={this.toggleMenu}
                data-target="#topnav-menu-content"
              >
                <i className="fa fa-fw fa-bars" />
              </button>
            </div>
            <div
              style={{
                display: 'flex',
                marginLeft: isMobile ? '0' : '38vw',
              }}
              className="mt-2 d-flex"
            >
              <p className="mt-1 ">Application ID {APPLICATION_ID}</p>
            </div>
            <div className="d-flex">
              <AlertDevice data={listCurrent} />
              <Notification data={list} />
              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  openLeftMenuCallBack: PropTypes.func,
  t: PropTypes.any,
};

const mapStatetoProps = state => ({
  result: safeDeepGet(state, ['businessPoint', 'notification'], {}),
  test: state,
  // const { layoutType } = state.Layout;
  resultCurrent: safeDeepGet(state, ['alert', 'list_alert_current'], {}),

  // return { layoutType };
});
const mapDispatchToProps = dispatch => ({
  GetNotifications: () => dispatch(notification()),
  LoadCurrentList: payload => dispatch(list_alert_current(payload)),
});
export default connect(mapStatetoProps, mapDispatchToProps)(Header);
